<template>
    <el-container class="content">
        <el-aside class="left">
            <div class="left-content">
                <h1 class="left-content_header">角色列表</h1>
                <roleList
                    :key="roleDateTime"
                    :roleId="roleId"
                    :listAll="listAll"
                    @addRole='addRole'
                    @setRoleId="setRoleId"
                    @handleDelet="handleDelet"
                    @handleRename="handleRename"
                    @handleSelect="handleSelect"
                ></roleList>
            </div>
        </el-aside>
        <el-main class="right">
            <div
                class="right-content"
                ref="box"
                :style="{height: boxHeight + 'px'}"
            >
                <h1 class="left-content_header">运营人员权限</h1>
                <permissionList
                    :roleId="roleId"
                    :key="permissionDateTime"
                    :listAll="listAll"
                    :permissionsList="permissionsList"
                    :roleName="roleName"
                    :boxHeight="boxHeight"
                    @saveBtn="saveBtn"
                ></permissionList>
            </div>
        </el-main>

        <!-- 角色重命名弹窗 -->
        <el-dialog
            title="角色重命名"
            :visible.sync="dialogFormVisible"
            width="400px"
        >
            <span>角色名称：</span>
            <el-input
                placeholder="请输入"
                v-model="roleName"
                size="small"
                style="width:250px"
            >
            </el-input>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="handleSave"
                >确 定</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>

<script>
import {
  roleDel,
  getRolePermissionsList,
  modifyRole,
  getMenu,
  getUserPerList
} from "@/request/api";
import permissionList from "./components/permissionList";
import roleList from "./components/roleList";

export default {
  data() {
    return {
      boxHeight: 0,
      dialogFormVisible: false,
      roleName: "",
      roleDateTime: null,
      permissionDateTime: null,
      roleId: "",
      isShow: false,
      listAll: [],
      permissionsList: [],
      reNameId: ""
    };
  },
  created() {
    this.getMenuList();
  },
  watch: {
    roleId(val) {
      val ? this.getPerList(val) : "";
      //   this.isShow = val ? true : false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getHeight();
    });
  },
  methods: {
    getMenuList() {
      getMenu({}).then(res => {
        if (res.status == 200) {
          this.permissionsList = res.data;
          //   this.permissionsList.forEach(item => {
          //     if (item.level == 1) {
          //       item.checkAll = false;
          //       item.ischeckAll = [];
          //     }
          //     if (item.level == 2) {
          //       item.childRightResp.forEach(items => {
          //         items.checkAll = false;
          //         items.ischeckAll = [];
          //       });
          //     }
          //   });

          //   this.permissionsList.forEach(item => {
          //     if (item.level == 1) {
          //       item.childRightResp.forEach(items => {
          //         for (let i = 0; i < this.listAll.length; i++) {
          //           if (this.listAll[i] == items.rtId) {
          //             item.ischeckAll.push(this.listAll[i]);
          //           }
          //         }
          //       });
          //       item.checkAll =
          //         item.ischeckAll.length == item.childRightResp.length
          //           ? true
          //           : false;
          //     } else if (item.level == 2) {
          //       item.childRightResp.forEach(items => {
          //         items.childRightResp.forEach(res => {
          //           for (let i = 0; i < this.listAll.length; i++) {
          //             if (this.listAll[i] == res.rtId) {
          //               items.ischeckAll.push(this.listAll[i]);
          //             }
          //           }
          //         });
          //         items.checkAll =
          //           items.ischeckAll.length == items.childRightResp.length
          //             ? true
          //             : false;
          //       });
          //     }
          //   });
          //   this.permissionsList.splice();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            center: "true"
          });
        }
      });
    },
    setRoleId(val) {
      this.roleId = val;
    },
    getPerList(val) {
      getRolePermissionsList({ reId: val }).then(res => {
        if (res.status == 200) {
          this.listAll = res.data;
          //   localStorage.setItem("userPermissionsList", JSON.stringify(res.data));
          this.permissionDateTime = new Date().getTime();
          //   this.isShow = true;
        } else {
          this.$message({
            message: res.message,
            type: "error",
            center: "true"
          });
        }
      });
    },
    // 删除
    handleDelet(id) {
      this.$confirm("是否删除此角色", "删除角色", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          roleDel({ reId: id }).then(res => {
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.permissionDateTime = new Date().getTime();
              this.roleDateTime = new Date().getTime();
            } else {
              this.$message({
                message: res.message,
                type: "error",
                center: "true"
              });
            }
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    addRole(val) {
      if (val) {
        this.permissionDateTime = new Date().getTime();
        this.roleDateTime = new Date().getTime();
      }
    },
    getHeight() {
      let h = window.innerHeight || document.body.clientHeight;
      let disTop = this.$refs.box;
      h -= disTop.offsetTop + 20;
      if (disTop.offsetParent) h -= disTop.offsetParent.offsetTop;
      this.boxHeight = h;
    },
    handleSelect(item) {
      //   this.getPerList(item.reId);
      this.roleId = item.reId;
      this.roleName = item.reName;
    },
    // 重命名
    handleRename(item) {
      this.getPerList(item.reId);
      this.dialogFormVisible = true;
      this.roleName = item.reName;
      this.roleId = item.reId;
    },
    // 重命名保存
    handleSave() {
      let opt = {
        reId: this.roleId,
        roleName: this.roleName,
        rtIds: this.listAll
      };
      modifyRole(opt).then(res => {
        if (res.status == 200) {
          this.dialogFormVisible = false;
          this.$message({
            type: "success",
            message: "名称修改成功!",
            center: "true"
          });
          this.permissionDateTime = new Date().getTime();
          this.roleDateTime = new Date().getTime();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            center: "true"
          });
        }
      });
    },
    // 权限保存
    saveBtn(val) {
      this.$message({
        message: "保存成功",
        type: "success",
        center: val
      });
      this.getPerList(this.roleId);
      this.getUserPermissionsList();
    },
    // 存储当前用户角色权限
    getUserPermissionsList() {
      getUserPerList({}).then(res => {
        if (res.status == 200) {
          localStorage.setItem("userPermissionsList", JSON.stringify(res.data));
        }
      });
    }
  },
  components: {
    permissionList,
    roleList
  }
};
</script>

<style lang="stylus" scoped>
.left {
    width: 300px;
    padding: 20px 0 20px 20px;
    background-color:#f4f4f4;
}

.right {
    width: 100%;
    height: 100%;
    background-color:#f4f4f4;
}

.left-content {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
}

.left-content_header {
    font-size: $fz16;
    color: #333;
    font-weight: bold;
    padding: 15px 0 20px 20px;
}

.right-content {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
}
</style>

