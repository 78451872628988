<template>
    <div>
        <ul>
            <li
                v-for="item in list"
                :key="item.reId"
                class="left-content_header-item"
                :class="{'active': isActive == item.reId}"
                @click="handleSelect(item)"
            >
                <p class="txov">{{ item.reName }}</p>
                <p v-perm="'/right/role/modifyRole'"><span @click.stop="handleRename(item)">重命名</span><span @click.stop="handleDelet(item)">删除</span></p>
            </li>
        </ul>
        <div
            class="left-content_handle"
            v-perm="'/right/role/modifyRole'"
        >
            <el-input
                v-model="input"
                placeholder="请输入"
                style="width: 150px;"
                size="small"
            ></el-input>
            <el-button
                size="mini"
                type="primary"
                @click="addRole"
            >添加角色</el-button>
        </div>
    </div>
</template>

<script>
import { getRoleList, modifyRole } from "@/request/api";
export default {
  props: {
    listAll: { type: Array, default: () => [] },
    roleId: { type: String, default: "" }
  },
  data() {
    return {
      isActive: null,
      list: [],
      input: ""
    };
  },
  created() {
    this.getLeftList();
  },
  watch: {
    isActive(val) {
      this.$emit("setRoleId", val);
    }
  },
  methods: {
    getLeftList() {
      getRoleList({}).then(res => {
        if (res.status == 200) {
          this.list = res.data;
          this.isActive = this.list[0].reId;
        } else {
          this.$message({
            message: res.message,
            type: "error",
            center: "true"
          });
        }
      });
    },
    handleRename(item) {
      this.$emit("handleRename", item);
    },
    handleDelet(item) {
      this.$emit("handleDelet", item.reId);
    },
    // 添加角色
    addRole() {
      if (this.input) {
        this.getAddNew(this.input);
      } else {
        this.$message({
          message: "请输入角色名称",
          type: "warning",
          center: "true"
        });
      }
    },
    getAddNew(val) {
      let opt = {
        roleName: val,
        rtIds: [],
        reId: ""
      };
      modifyRole(opt).then(res => {
        if (res.status == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
            center: "true"
          });
          this.$emit("addRole", true);
        } else {
          this.$message({
            message: res.message,
            type: "error",
            center: "true"
          });
        }
      });
    },
    handleSelect(item) {
      this.isActive = item.reId;
      this.$emit("handleSelect", item);
    }
  }
};
</script>

<style lang="stylus" scoped>
.left-content_header-item {
    width: 100%;
    transition: all 0.3s;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .txov {
        width: 130px;
        padding: 0 0 0 20px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    p {
        line-height: 30px;
        padding: 0 20px;

        span {
            font-size: 12px;
            color: $spColor;
            margin: 0 7px;
            line-height: 30px;
            display: inline-block;
        }
    }
}

.left-content_handle {
    margin-top: 10px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}

.active {
    background: $spColor;
    color: #fff;

    p {
        span {
            color: #fff;
        }
    }
}
</style>



