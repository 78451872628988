<template>
  <div class="box" :style="{ height: height + 'px' }">
    <div class="pm-box" v-for="item in permissionsLs" :key="item.rtId">
      <template v-if="item.level == 1">
        <div class="pm-box_header mt16">
          <el-checkbox
            v-model="item.checkAll"
            @change="handleCheckAllChange(item.rtId, item.checkAll, '1')"
            >{{ item.rtName }}</el-checkbox
          >
        </div>
        <div class="pm-box_content">
          <el-checkbox-group v-model="item.ischeckAll">
            <el-checkbox
              @change="setCheck(item.rtId, '1')"
              v-for="permissions in item.childRightResp"
              :label="permissions.rtId"
              :key="permissions.rtId"
              class="mt16"
              >{{ permissions.rtName }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </template>
      <template v-if="item.level == 2">
        <div class="pm-box_header mt16">
          <p class="pm-box_header-p">{{ item.rtName }}</p>
        </div>
        <div v-for="items in item.childRightResp" :key="items.rtId">
          <div class="pm-box_header-sp mt16">
            <el-checkbox
              v-model="items.checkAll"
              @change="handleCheckAllChange(items.rtId, items.checkAll, '2')"
              >{{ items.rtName }}</el-checkbox
            >
          </div>
          <div class="pm-box_content">
            <el-checkbox-group v-model="items.ischeckAll">
              <el-checkbox
                @change="setCheck(items.rtId, '2')"
                v-for="permissions in items.childRightResp"
                :label="permissions.rtId"
                :key="permissions.rtId"
                class="mt16"
                >{{ permissions.rtName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </template>
    </div>
    <div class="btn-style" v-perm="'/right/role/modifyRole'">
      <el-button type="primary" @click="saveBtn" class="save-class"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { modifyRole } from '@/request/api'

export default {
  props: {
    listAll: { type: Array, default: () => [] },
    permissionsList: { type: Array, default: () => [] },
    roleId: { type: String, default: '' },
    roleName: { type: String, default: '' },
    boxHeight: { type: [Number, String], default: () => null },
  },
  data() {
    return {
      permissionsLs: this.permissionsList,
      height: this.boxHeight,
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.height = this.height - 51
  },
  methods: {
    getInfo() {
      this.permissionsLs.forEach((item) => {
        if (item.level == 1) {
          item.checkAll = false
          item.ischeckAll = []
        }
        if (item.level == 2) {
          item.childRightResp.forEach((items) => {
            items.checkAll = false
            items.ischeckAll = []
          })
        }
      })

      this.permissionsLs.forEach((item) => {
        if (item.level == 1) {
          item.childRightResp.forEach((items) => {
            for (let i = 0; i < this.listAll.length; i++) {
              if (this.listAll[i] == items.rtId) {
                item.ischeckAll.push(this.listAll[i])
              }
            }
          })
          item.checkAll =
            item.ischeckAll.length == item.childRightResp.length ? true : false
        } else if (item.level == 2) {
          item.childRightResp.forEach((items) => {
            items.childRightResp.forEach((res) => {
              for (let i = 0; i < this.listAll.length; i++) {
                if (this.listAll[i] == res.rtId) {
                  items.ischeckAll.push(this.listAll[i])
                }
              }
            })
            items.checkAll =
              items.ischeckAll.length == items.childRightResp.length
                ? true
                : false
          })
        }
      })
      this.permissionsLs.splice()
    },
    handleCheckAllChange(id, checkAll, level) {
      if (level == 1) {
        for (let val of this.permissionsLs) {
          if (val.rtId == id) {
            val.ischeckAll = checkAll
              ? val.childRightResp.map((item) => item.rtId)
              : []
          }
        }
      } else {
        for (let val of this.permissionsLs) {
          for (let vals of val.childRightResp) {
            if (vals.rtId == id) {
              vals.ischeckAll = checkAll
                ? vals.childRightResp.map((item) => item.rtId)
                : []
            }
          }
        }
      }

      this.permissionsLs.splice()
    },
    setCheck(id, level) {
      if (level == '1') {
        for (let val of this.permissionsLs) {
          if (val.rtId == id) {
            // 判断已经选中的列表是否和选项列表数量一致，如果一致为true，否则为false
            val.checkAll =
              val.ischeckAll.length == val.childRightResp.length ? true : false
          }
        }
      } else {
        for (let val of this.permissionsLs) {
          for (let vals of val.childRightResp) {
            if (vals.rtId == id) {
              // 判断已经选中的列表是否和选项列表数量一致，如果一致为true，否则为false
              vals.checkAll =
                vals.ischeckAll.length == vals.childRightResp.length
                  ? true
                  : false
            }
          }
        }
      }
      this.permissionsLs.splice()
    },
    saveBtn() {
      let arr = []
      this.permissionsLs.forEach((item) => {
        if (item.level == 1) {
          if (item.ischeckAll && item.ischeckAll.length > 0) {
            arr.push(item.rtId)
            arr.push(...item.ischeckAll)
          }
        } else if (item.level == 2) {
          item.childRightResp.forEach((items) => {
            if (items.ischeckAll && items.ischeckAll.length > 0) {
              arr.push(item.rtId)
              arr.push(items.rtId)
              arr.push(...items.ischeckAll)
            }
          })
        }
      })
      let opt = {
        reId: this.roleId,
        roleName: this.roleName,
        rtIds: arr,
      }
      modifyRole(opt).then((res) => {
        if (res.status == 200) {
          this.$emit('saveBtn', true)
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            center: 'true',
          })
        }
      })
    },
    flatten(array) {
      let arr = [].concat(...array)
      return [...new Set(arr)]
    },
  },
}
</script>

<style lang="stylus" scoped>
.box {
    position: relative;
}

.pm-box {
    padding: 0 20px;
}

.pm-box_header {
    padding: 0 20px;
    background: #eee;
    height: 30px;
    display: flex;
    align-items: center;
}

.pm-box_header-p {
    font-size: 14px;
    color: #606266;
    line-height: 19px;
    font-weight: bold;
    padding-left: 5px;
}

.pm-box_header-sp {
    padding: 0 40px;
    height: 26px;
    display: flex;
    align-items: center;
}

.pm-box_content {
    padding: 0 100px;
}

.mt16 {
    margin-bottom: 16px;
}

.btn-style {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    // position: absolute;
    // bottom: 50px;
    width: 100%;
}
</style>
